import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

import { request } from '../authConfig';

export default function useApiClient() {
  const { instance } = useMsal();

  const getApiClient = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...request,
        redirectUri: document.location.origin + '/blank.html',
        account: instance.getActiveAccount(),
      });
      let requestHeaders = {
        Authorization: `Bearer ${response.accessToken}`,
      };

      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        requestHeaders['x-api-key'] = process.env.REACT_APP_POSTMAN_API_KEY;
      }

      let baseUrl =
        process.env.REACT_APP_DEVELOPER_PORTAL_SERVICE_HOST + process.env.REACT_APP_DEVELOPER_PORTAL_SERVICE_BASEPATH;

      return axios.create({
        baseURL: baseUrl,
        headers: requestHeaders,
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interactive redirect flow if silent acquisition fails
        instance.acquireTokenRedirect({ ...request, account: instance.getActiveAccount() });
        // Throw an error to indicate that token acquisition failed and
        // the user needs to complete the interactive flow.
        throw new Error('Failed to acquire token silently. Please re-authenticate.');
      } else {
        // Handle other errors
        console.error('Error acquiring token:', error);
        throw error; // Re-throw the error for handling in the calling component
      }
    }
  };

  return { apiClient: getApiClient };
}
